import Button from "@/components/Button";
import { TIMEOUT_LOAD_SEARCH } from "@/constants/common-constant";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import { ValueLocation } from "@/models/location";
import { isNumeric } from "@/utils/number";
import axios from "axios";
import clsx from "clsx";
import { unionBy } from "lodash";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";

/* eslint-disable @next/next/no-img-element */
const SelectWard = ({
  valueLocation,
  setValueLocation,
  allowConfirm,
  onConfirm,
}: {
  valueLocation?: ValueLocation;
  setValueLocation?: Dispatch<SetStateAction<ValueLocation>>;
  allowConfirm?: boolean;
  onConfirm?: (value?: ValueLocation) => void;
}) => {
  const [data, setData] = useState({
    results: [],
    totalResults: 0,
    totalPages: 0,
    page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const listDataRender = useMemo(() => {
    return search?.length
      ? data.results
      : unionBy(valueLocation.ward, data.results, "_id");
  }, [data.results, search, valueLocation.ward]);

  const getData = useCallback(
    async (params?: any) => {
      setLoading(true);
      try {
        const res = await axios.request({
          baseURL: MEEY_REVIEW_API_URL,
          url: "/v1/wards",
          method: "post",
          data: {
            limit: 10,
            page: 1,
            name: search,
            sort: "name:asc",
            district: valueLocation.district?.map((e) => e._id),
            ...params,
          },
        });
        setData({
          ...res.data,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [search, valueLocation.district]
  );

  const loadMoreData = useCallback(
    async (page?: number) => {
      setLoading(true);
      try {
        const res = await axios.request({
          baseURL: MEEY_REVIEW_API_URL,
          url: "/v1/wards",
          method: "post",
          data: {
            limit: 10,
            page: page ?? 1,
            name: search,
            sort: "name:asc",
            district: valueLocation.district?.map((e) => e._id),
          },
        });
        setData({
          ...res.data,
          results: page
            ? [...data.results, ...res.data.results]
            : res.data.results,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [data.results, search, valueLocation.district]
  );
  useEffect(() => {
    getData();
  }, []);
  const timeoutSearch = useRef(null);

  return (
    <>
      <div
        className={clsx(
          "flex gap-1 items-center mx-3 px-3 py-2 bg-[rgba(0,0,0,0.05)] rounded flex-0",
          allowConfirm ? "mb-2" : ""
        )}>
        <i className="mrv mrv-search text-fs-20" />
        <input
          value={search}
          onChange={(e) => {
            clearTimeout(timeoutSearch.current);
            setSearch(e.target.value?.slice(0, 100));
            if (!loading)
              timeoutSearch.current = setTimeout(() => {
                getData({
                  name: e.target.value?.slice(0, 100),
                });
              }, TIMEOUT_LOAD_SEARCH);
          }}
          onKeyUp={(e: any) => {
            if (e.key === "Enter" && !loading) {
              clearTimeout(timeoutSearch.current);

              getData({
                name: e.target.value?.slice(0, 100),
              });
            }
          }}
          placeholder="Tìm Phường/Xã"
          className="h-[28px] mr-5 w-full text-base bg-transparent"
        />
      </div>

      {
        <>
          {allowConfirm ? null : (
            <div className="flex justify-between items-center border-b border-b-grey-300  mx-3">
              <div className="text=grey-800 font-medium">
                {valueLocation.ward.length} lựa chọn
              </div>
              <div
                className="text-grey-600 py-2.5 px-1 flex items-center gap-1  cursor-pointer"
                onClick={() => {
                  setValueLocation({
                    city: valueLocation.city,
                    district: valueLocation.district,
                    ward: [],
                  });
                }}>
                <i className="mrv mrv-restart_alt"></i>
                Đặt lại
              </div>
            </div>
          )}
          <div
            className={clsx(
              "w-full h-full overflow-auto px-3 flex-1"
              // allowConfirm ? "pb-0" : "pb-3"
            )}
            id="scrollablePopup">
            <InfiniteScroll
              dataLength={data.results.length || 0}
              hasMore={data.page < data.totalPages}
              next={() => {
                loadMoreData(data.page + 1);
              }}
              scrollableTarget="scrollablePopup"
              className=" md:gap-4 gap-1  overflow-auto w-full"
              loader={
                <div className="px-4 flex items-center justify-center">
                  <div className="dot-flashing" />
                </div>
              }>
              {listDataRender?.map((ward) => {
                const isSelect = valueLocation.ward.find(
                  (item) => item._id === ward._id
                );
                return (
                  <div
                    key={ward._id}
                    onClick={() => {
                      setValueLocation({
                        city: valueLocation.city,
                        district: valueLocation.district,
                        ward: isSelect
                          ? valueLocation.ward?.filter(
                              (item) => item._id !== ward._id
                            )
                          : [...valueLocation.ward, ward],
                      });
                    }}
                    className="py-3 flex items-center hover:bg-[rgba(0,0,0,0.05)] duration-300 cursor-pointer ">
                    <i
                      className={clsx(
                        "mrv   text-fs-24 mr-2",
                        isSelect
                          ? "mrv-check_box text-blue-500"
                          : "mrv-check_box_outline_blank"
                      )}></i>
                    {isNumeric(ward?.name) ? ward.title : ward?.name}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
          {allowConfirm ? (
            <div className="flex gap-2 py-2 px-3 border-t border-t-grey-200 flex-0">
              <Button
                className="w-full flex justify-center !text-grey-600 hover:!bg-transparent"
                btnType="link"
                onClick={() => {
                  setValueLocation({
                    city: valueLocation.city,
                    district: valueLocation.district,
                    ward: [],
                  });
                }}>
                <i className="mrv mrv-restart_alt mr-2"></i>
                Đặt lại{" "}
              </Button>
              <Button
                className="w-full flex justify-center"
                btnType="filled"
                onClick={() => {
                  onConfirm && onConfirm(valueLocation);
                }}>
                Xác nhận{" "}
              </Button>
            </div>
          ) : null}
        </>
      }
    </>
  );
};

export default SelectWard;
