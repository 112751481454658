import PopOver, { PopOverPositionType } from "@/components/PopOver";
import { ValueLocation } from "@/models/location";
import clsx from "clsx";
import { groupBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import PopupSelectLocation from "./PopupSelectLocation";

/* eslint-disable @next/next/no-img-element */
const LocationSelect = ({
  value,
  onChange,
  inputClassName,
  toolTipPosition,
  allowConfirm,
  onConfirm,
}: {
  value?: ValueLocation;
  inputClassName?: string;
  onChange?: (value: any) => void;
  toolTipPosition?: PopOverPositionType;
  allowConfirm?: boolean;
  onConfirm?: (value?: ValueLocation) => void;
}) => {
  const [valueLocation, setValueLocation] = useState<ValueLocation>({
    city: [],
    district: [],
    ward: [],
  });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setValueLocation({
      city: value?.city ?? [],
      district: value?.district ?? [],
      ward: value?.ward ?? [],
    });
  }, [value]);

  const showNameValue = useMemo(() => {
    return valueLocation.city.length ||
      valueLocation.district.length ||
      valueLocation.ward.length
      ? `${
          valueLocation.ward.length
            ? valueLocation.ward.map((ward) => ward.name).join(", ") + ","
            : ""
        } ${
          valueLocation.district?.length
            ? valueLocation.district
                .map((district) => district.name)
                .join(", ") + ","
            : ""
        } ${
          valueLocation.city?.length
            ? valueLocation.city.map((city) => city.name).join(", ")
            : ""
        }`
      : "Toàn quốc";
  }, [valueLocation.city, valueLocation.district, valueLocation.ward]);

  const toolTipValueName = useMemo(() => {
    const listWardByDistrict = groupBy(valueLocation.ward, (e) => e.deps[0]);

    return valueLocation?.city.length ||
      valueLocation?.district.length ||
      valueLocation?.ward.length ? (
      <>
        {Object.keys(listWardByDistrict).map((districtId) => {
          const currentDistrict = valueLocation.district.find(
            (e) => e._id === districtId
          );
          const currentCity = valueLocation.city.find(
            (e) => e._id === currentDistrict?.deps?.[0]
          );
          return (
            <div className="" key={districtId}>
              {listWardByDistrict[districtId]?.map((ward) => ward.name + ", ")}{" "}
              {currentDistrict.name + ", " + currentCity.name}
            </div>
          );
        })}

        {valueLocation.district
          .filter(
            (item) =>
              !valueLocation.ward.find((e) => e.deps?.includes(item._id))
          )
          .map((item) => {
            const currentCity = valueLocation.city.find(
              (e) => e._id === item?.deps?.[0]
            );
            return (
              <div className="" key={item._id}>
                {item.name + ", " + currentCity?.name}
              </div>
            );
          })}

        {valueLocation.city
          .filter(
            (item) =>
              !valueLocation.district.find((e) => e.deps?.includes(item._id))
          )
          .map((item) => {
            return (
              <div className="" key={item._id}>
                {item.name}
              </div>
            );
          })}
      </>
    ) : null;
  }, [valueLocation.city, valueLocation.district, valueLocation.ward]);

  // useEffect(() => {
  //   if (open) lockScroll();
  //   else unlockScroll();
  //   // return () => {
  //   //   unlockScroll();
  //   // };
  // }, [open]);
  return (
    <>
      <PopOver
        content={
          <PopupSelectLocation
            valueLocation={valueLocation}
            setValueLocation={(data) => {
              setValueLocation(data);
              onChange && onChange(data);
            }}
            allowConfirm={allowConfirm}
            onConfirm={(value) => {
              setOpen(false);
              onConfirm && onConfirm(value);
            }}
          />
        }
        open={open}
        className="h-full"
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        position="bottom-right"
        dropdownClassName="mt-4 rounded !p-0">
        <PopOver
          trigger="hover"
          content={toolTipValueName}
          position={toolTipPosition ?? "bottom-right"}
          // className={clsx(
          //   "px-2 flex items-center border-r border-r-grey-200 rounded-none",
          //   inputClassName
          // )}
          className="h-full"
          wrapTriggerClassName="h-full"
          dropdownClassName="!z-[20] !bg-[rgba(0,0,0,0.9)] text-white rounded overflow-auto flex flex-col gap-2 text-fs-14 px-4 py-2">
          <div
            className={clsx(
              "px-2 flex items-center border-r border-r-grey-200 rounded-none ",
              inputClassName
            )}>
            <div className="w-[8.5rem] line-clamp-1 select-none">
              {showNameValue}{" "}
            </div>
            <i
              className={clsx(
                "mrv mrv-keyboard_arrow_down text-fs-20 text-grey-500 ml-3 duration-300",
                open ? "rotate-180" : "rotate-0"
              )}></i>
          </div>
        </PopOver>
      </PopOver>
    </>
  );
};

export default LocationSelect;
