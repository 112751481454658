import { ValueLocation } from "@/models/location";
import clsx from "clsx";
import { Dispatch, SetStateAction, cloneElement, useState } from "react";
import SelectCity from "./SelectCity";
import SelectDistrict from "./SelectDistrict";
import SelectWard from "./SelectWard";

/* eslint-disable @next/next/no-img-element */
const tabs = [
  {
    label: "Tỉnh/Thành phố",
    key: "city",
    child: <SelectCity />,
  },
  {
    label: "Quận/Huyện",
    key: "district",
    child: <SelectDistrict />,
  },
  {
    label: "Phường/Xã",
    key: "ward",
    child: <SelectWard />,
  },
];
const PopupSelectLocation = ({
  valueLocation,
  setValueLocation,
  allowConfirm,
  onConfirm,
}: {
  valueLocation?: ValueLocation;
  setValueLocation?: Dispatch<SetStateAction<ValueLocation>>;
  allowConfirm?: boolean;
  onConfirm?: (value?: ValueLocation) => void;
}) => {
  const [activeTab, setActviveTab] = useState("city");
  // const

  return (
    <>
      <div className="flex">
        <div
          className={clsx(
            "px-4 py-3 font-medium cursor-pointer border-b border-b-grey-200 duration-300",
            activeTab === "city" ? "text-blue-500 !border-b-blue-500" : "",
            "hover:border-b-blue-500"
          )}
          onClick={() => setActviveTab("city")}>
          Tỉnh/Thành phố
        </div>

        <div
          className={clsx(
            "px-4 py-3 font-medium cursor-pointer border-b border-b-grey-200 duration-300",
            "hover:border-b-blue-500",
            activeTab === "district" ? "text-blue-500 !border-b-blue-500" : "",
            !valueLocation.city?.length
              ? "text-grey-200 !cursor-not-allowed border-b-grey-200"
              : "",
            "hover:border-b-blue-500"
          )}
          onClick={() =>
            valueLocation.city.length && setActviveTab("district")
          }>
          Quận/Huyện
        </div>
        <div
          className={clsx(
            "px-4 py-3 font-medium cursor-pointer border-b border-b-grey-200 duration-300",
            "hover:border-b-blue-500",
            activeTab === "ward" ? "text-blue-500 !border-b-blue-500" : "",
            !valueLocation.district?.length
              ? "text-grey-200 !cursor-not-allowed border-b-grey-200"
              : ""
          )}
          onClick={() =>
            valueLocation.district.length && setActviveTab("ward")
          }>
          Phường/Xã
        </div>
      </div>
      <div className="h-[24rem] pt-3 overflow-auto flex flex-col">
        {cloneElement(tabs.find((tab) => tab.key === activeTab)?.child, {
          valueLocation,
          setValueLocation,
          setActviveTab,
          allowConfirm,
          onConfirm,
        })}
        {/* <div className="flex">
          <Button></Button>
        </div> */}
      </div>
    </>
  );
};

export default PopupSelectLocation;
