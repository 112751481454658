import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import { ValueLocation } from "@/models/location";
import { isNumeric } from "@/utils/number";
import Button from "@components/Button";
import axios from "axios";
import clsx from "clsx";
import { unionBy } from "lodash";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

/* eslint-disable @next/next/no-img-element */
const SelectDistrict = ({
  valueLocation,
  setValueLocation,
  setActviveTab,
  allowConfirm,
  onConfirm,
}: {
  valueLocation?: ValueLocation;
  allowConfirm?: boolean;
  onConfirm?: (value?: ValueLocation) => void;
  setActviveTab?: Dispatch<SetStateAction<string>>;
  setValueLocation?: Dispatch<SetStateAction<ValueLocation>>;
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const listDataRender = useMemo(() => {
    return unionBy(valueLocation.district, data, "_id").filter((e) =>
      e.title.toLowerCase().includes(search.toLowerCase())
    );
  }, [data, valueLocation.district, search]);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.request({
        baseURL: MEEY_REVIEW_API_URL,
        url: "/v1/districts",
        method: "post",
        data: {
          limit: 500,
          city: valueLocation?.city?.[0]._id,
        },
      });
      setData(res.data?.results);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [valueLocation?.city]);
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <div
        className={clsx(
          "flex gap-1 items-center mx-3 px-3 py-2 bg-[rgba(0,0,0,0.05)] rounded",
          allowConfirm ? "mb-2" : ""
        )}>
        <i className="mrv mrv-search text-fs-20" />
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value?.slice(0, 100))}
          placeholder="Tìm Quận/Huyện"
          className="h-[28px] mr-5 w-full text-base bg-transparent"
        />
      </div>

      {loading ? (
        <div className="w-full h-full flex justify-center py-4">
          <div className={"dot-flashing"}></div>
        </div>
      ) : (
        <>
          {allowConfirm ? null : (
            <div className="flex justify-between items-center border-b border-b-grey-300 mx-3">
              <div className="text=grey-800 font-medium">
                {valueLocation.district.length} lựa chọn
              </div>
              <div
                className="text-grey-600 py-2.5 px-1 flex items-center gap-1  cursor-pointer"
                onClick={() => {
                  setValueLocation({
                    city: valueLocation.city,
                    district: [],
                    ward: [],
                  });
                }}>
                <i className="mrv mrv-restart_alt"></i>
                Đặt lại
              </div>
            </div>
          )}
          <div
            className={clsx(
              "w-full h-full overflow-auto px-3",
              allowConfirm ? "mb-0" : "mb-3"
            )}>
            {listDataRender?.map((district) => {
              const cityTranslate = district;
              const isSelect = valueLocation.district.find(
                (item) => item._id === district._id
              );
              return (
                <div
                  key={district._id}
                  onClick={() => {
                    setValueLocation({
                      city: valueLocation.city,
                      district: isSelect
                        ? valueLocation.district?.filter(
                            (item) => item._id !== district._id
                          )
                        : [...valueLocation.district, district],
                      ward: [],
                    });
                  }}
                  className="py-3 flex items-center hover:bg-[rgba(0,0,0,0.05)] duration-300 cursor-pointer ">
                  <i
                    className={clsx(
                      "mrv   text-fs-24 mr-2",
                      isSelect
                        ? "mrv-check_box text-blue-500"
                        : "mrv-check_box_outline_blank"
                    )}></i>
                  {isNumeric(cityTranslate?.name)
                    ? cityTranslate.title
                    : cityTranslate?.name}
                </div>
              );
            })}
          </div>
          {allowConfirm ? (
            <div className="flex gap-2 py-2 px-3 border-t border-t-grey-200">
              <Button
                className="w-full flex justify-center !text-grey-600 hover:!bg-transparent"
                btnType="link"
                onClick={() => {
                  setValueLocation({
                    city: valueLocation.city,
                    district: [],
                    ward: [],
                  });
                }}>
                <i className="mrv mrv-restart_alt mr-2"></i>
                Đặt lại{" "}
              </Button>
              <Button
                className="w-full flex justify-center"
                btnType="filled"
                onClick={() => {
                  onConfirm && onConfirm(valueLocation);
                }}>
                Xác nhận{" "}
              </Button>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default SelectDistrict;
