import React, { useEffect, useState } from "react";
import MeeyImage from "@/components/MeeyImage";
import Link from "next/link";
import { useAuth } from "@/hooks/useAuth";
import Button from "@/components/Button";
import SearchProject from "../Home/HeaderHome/SearchProject";
import PopupAvatarUser from "@/components/PopupAvatarUser";

import { useRouter } from "next/router";
import { STATIC_DOMAIN } from "@/constants/environment";
import clsx from "clsx";
import PopupNotification from "@/components/PopupNotification";
import dynamic from "next/dynamic";
const ModalAddReview = dynamic(import("../Home/HeaderHome/ModalAddReview"), {
  ssr: false,
});
const HeaderListSearchProject = ({
  isShowScrolHeader,
  showSearch,
}: {
  isShowScrolHeader?: boolean;
  showSearch?: boolean;
}) => {
  const { user, login } = useAuth();
  const { push } = useRouter();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div
      className={clsx(
        "bg-white flex items-center justify-between h-14 w-full pl-10 pr-[60px] fixed duration-300  z-30 shadow-light-2",
        isShowScrolHeader ? "top-0" : "-top-full"
      )}>
      <Link href={"/"}>
        <MeeyImage
          alt="home"
          src={STATIC_DOMAIN + "/logo_blue.svg"}
          resizeOnFly={false}
          resizeOptions={{
            width: 131,
            height: 42,
          }}
        />
      </Link>

      {showSearch ? (
        <div className="flex items-center w-[26.5rem] h-10 rounded-md py-2 pl-3 border border-solid border-grey-200">
          <i className="mrv mrv-search text-fs-20 text-grey-500"></i>
          {mounted ? (
            <SearchProject
              showSuffixIcon={false}
              className="w-[calc(100%-32px)]"
              allowClear={true}
            />
          ) : null}
        </div>
      ) : null}
      <div className="flex items-center gap-4">
        <ModalAddReview
          buttonClassName="h-9"
          callBack={() => {
            push("/");
          }}
        />
        {!user ? (
          <Button
            color="white"
            onClick={login}
            className="meey-button meey-button-grey meey-button-outline py-2.5 px-4 h-9 flex items-center gap-1"
            btnType="outline"
            prefixIcon={<i className="mrv mrv-account_circle text-fs-18"> </i>}>
            Đăng nhập
          </Button>
        ) : null}

        <PopupNotification isShowScrolHeader={isShowScrolHeader} />
        <PopupAvatarUser />
      </div>
    </div>
  );
};

export default HeaderListSearchProject;
